import { polyfillStorageWhenNeeded, StorageTypes } from "@bokio/shared/utils/polyfillStorage";

polyfillStorageWhenNeeded(StorageTypes.localStorage);
polyfillStorageWhenNeeded(StorageTypes.sessionStorage);

/** Intl is not implemented in all major browsers, better to make sure it is available before using its functionality */
function checkIntlAndFunctionalityAvailable() {
	return (
		(globalThis.Intl?.DateTimeFormat.prototype.formatToParts as unknown | undefined) &&
		(globalThis.Intl?.NumberFormat.prototype.formatToParts as unknown | undefined)
	);
}

export const loadIntl = async () => {
	if (!checkIntlAndFunctionalityAvailable()) {
		const [intlModule] = await Promise.all([
			import(/* webpackChunkName: "intl" */ "intl"),
			import(/* webpackChunkName: "intl" */ "intl/locale-data/jsonp/en-GB"),
			import(/* webpackChunkName: "intl" */ "intl/locale-data/jsonp/en-SE"),
			import(/* webpackChunkName: "intl" */ "intl/locale-data/jsonp/sv-SE"),
		]);

		// @ts-expect-error SS 2024-10-30 not sure if this missing type comes from outdated package or something else
		intlModule.default.__disableRegExpRestore();
		// SS 2024-11-06 Force casting because for unknown reason this throws a type error while it actually shouldn't
		globalThis.Intl = (intlModule as unknown as { default: typeof globalThis.Intl }).default;
	} else {
		return Promise.resolve();
	}
};
